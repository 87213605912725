@import '../../styles/main';

.header {
  background-color: red;

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;

    @media screen and (min-width: $b-tablet) {
      padding: 24px;
    }

    @media screen and (min-width: $b-desktop) {
      padding: 24px 64px;
    }
  }

  &__list {
    display: none;
    position: absolute;
    z-index: 9;
    top: calc(100% + 1px);
    right: 0;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    flex-flow: column;
    text-align: center;

    &--open {
      display: flex;
      height: 100vh;
      background: white;
    }

    @media screen and (min-width: $b-tablet) {
      display: flex;
      position: inherit;
      flex-flow: row;
      width: auto;
    }
  }

  &__item {
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
    background-color: white;

    &:hover {
      text-decoration: underline;
    }

    @media screen and (min-width: $b-tablet){
      display: block;
      padding: 0 12px;
      border-bottom: none;
    }
  }

  &__link {
    text-decoration: none;
    font-family: $font-bold;
    font-size: 18px;
    color: black;
  }

  &__logoWrapper {
    margin-right: auto;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
  }

  &__logo {
    width: 50px;
    height: auto;
  }

  &__title {
    font-size: 40px;
    margin-left: 16px;
    display: block;
  }

  &__subtitle {
    margin-left: 8px;
    font-size: 14px;
  }

  &__burger {
    & > span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;
      background: black;
      border-radius: 3px;
      z-index: 1;

      transform-origin: 4px 0;

      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
    }

    span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }

    &--open {
      span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: #232323;
      }

     span:nth-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      span:nth-child(3) {
        transform-origin: 0% 0%;
        transform: rotate(-45deg) translate(0, -1px);
      }

    }

    @media screen and (min-width: $b-tablet) {
      display: none;
    }
  }
}