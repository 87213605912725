@import '../../styles/main';

.footer {
  display: flex;
  background: black;
  padding: 16px;
  color: white;

  @media screen and (min-width: $b-tablet) {
    padding: 24px 64px;
  }

  &__copyright,
  &__legal,
  &__privacy {
    font-family: $font-bold;
    font-size: 16px;
    color: white;

    &:active {
      color: white;
    }
  }

  &__image {
    max-width: 60px;
  }

  &__leftColumn {
    text-align: center;

    &-title {
      font-size: 27px;
      margin-bottom: 8px;
    }

    &-subtitle {
      font-size: 14px;
    }
  }

  &__rightColumn {
    text-align: right;
    flex: 1 1;
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: flex-end;
  }

  &__separator {
    height: 3px;
    width: 200px;
    background-color: white;
    margin: 24px 0 24px auto;
  }

  &__icon {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }
  &__policy {
    margin-top: 12px;
    a {
      display: inline-block;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
      
      &:last-child {
        margin-left: 16px;
      }
    }
  }

}