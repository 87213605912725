@font-face {
  font-family: 'Quicksand-Regular';
  src: url('../assets/fonts/Quicksand-Regular.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand-Bold';
  src: url('../assets/fonts/Quicksand-Bold.ttf');
  font-style: normal;
}