@import 'variables';
@import 'reset';
@import 'fonts';

// *:focus,
// *:active {
//   outline: none !important;
// }

body {
  font-family: $font-regular;
}